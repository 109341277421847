import {
	EXPORT_WAREHOUSE_ORDERS,
	EXPORT_WAREHOUSE_ORDERS_BATCH,
	EXPORT_WAREHOUSE_ORDERS_IMPORT_TEMPLATE,
	GET_PENDING_WAREHOUSE_ORDERS,
	GET_WAREHOUSE_ORDERS_BATCHES,
	GET_WAREHOUSE_ORDERS_EXCEPTIONS,
	GET_WAREHOUSE_ORDERS_WAREHOUSES,
	IMPORT_WAREHOUSE_ORDERS,
} from '@/config/endpoint';
import Req from './AxiosRequest';
import Req2 from './AxiosRequestEnhanced';

class WarehouseOrders {
	constructor() {
		const axios = new Req();
		const axios2 = new Req2();
		this.data = axios;
		this.data2 = axios2;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getWarehouses() {
		const { method, endpoint } = GET_WAREHOUSE_ORDERS_WAREHOUSES;
		return this.data2[method](endpoint)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	getPendingOrders(warehouseId, page) {
		const { method, endpoint } = GET_PENDING_WAREHOUSE_ORDERS;
		return this.data[method](endpoint(warehouseId, page)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	exportOrders(data) {
		const { method, endpoint } = EXPORT_WAREHOUSE_ORDERS;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	exportBatch(data) {
		const { method, endpoint } = EXPORT_WAREHOUSE_ORDERS_BATCH;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadImportTemplate(warehouseId) {
		const { method, endpoint } = EXPORT_WAREHOUSE_ORDERS_IMPORT_TEMPLATE;
		return this.data.getBlobData({ method, url: endpoint(warehouseId) }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBatches(warehouseId) {
		const { method, endpoint } = GET_WAREHOUSE_ORDERS_BATCHES;
		return this.data[method](endpoint(warehouseId))
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}

	importOrders(payload) {
		const { method, endpoint } = IMPORT_WAREHOUSE_ORDERS;

		const options = {};
		options.data = payload;
		options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		options.url = endpoint;
		options.method = method;

		return this.data.postFormData(options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getExceptions(warehouseId) {
		const { method, endpoint } = GET_WAREHOUSE_ORDERS_EXCEPTIONS;
		return this.data[method](endpoint(warehouseId))
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	}
}

export default WarehouseOrders;
