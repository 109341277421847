<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col
				cols="12"
				class="mb-3">
				<b-button
					:disabled="idsComputed.length === 0"
					class="float-right"
					variant="primary"
					@click="exportOrders()">
					<i class="fas fa-file-download" /> {{ translate('export') }}
				</b-button>
			</b-col>
			<b-col class="col-12">
				<div class="table-responsive">
					<table class="table table-hover table-striped">
						<thead>
							<tr>
								<th class="border-top-0">
									<input
										v-model="selectAll"
										:disabled="loading"
										type="checkbox">
								</th>
								<th class="border-top-0">
									#
								</th>
								<th class="border-top-0 text-left">
									{{ translate('order_paid_date') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('order_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('invoice_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('user_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('ship_to') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('country') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									<input
										v-model="ids[item.id]"
										type="checkbox">
								</td>
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td class="align-middle text-left">
									{{ $moment(item.attributes.order_paid_date.date).format(datetimeFormat) }}
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.order_id }}
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.invoice_id }}
								</td>
								<td class="align-middle text-left">
									<span class="badge badge-primary">{{ item.attributes.user_id }}</span>
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.ship_to }}
								</td>
								<td class="align-middle text-left">
									<img
										v-b-tooltip.hover
										class="ml-3"
										:title="translate(item.attributes.country_code.toLowerCase())"
										:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
										:alt="translate(item.attributes.country_code.toLowerCase())">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="hasData" />
				<b-pagination
					v-if="pagination.total_pages > 1"
					v-model="pagination.current_page"
					:total-rows="pagination.total"
					:per-page="pagination.per_page"
					align="center"
					@change="getDataFiltered" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Countries, Grids, WarehouseOrders } from '@/translations';
import WarehouseOrdersUtil from '@/util/WarehouseOrders';
import { YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'PendingWarehouseOrders',
	messages: [Grids, Countries, WarehouseOrders],
	data() {
		return {
			warehouseId: null,
			warehouseOrders: new WarehouseOrdersUtil(),
			download: new WarehouseOrdersUtil(),
			ids: {},
			selectAll: false,
			alert: new this.$Alert(),
			datetimeFormat: YMDHMS_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.warehouseOrders.data.loading;
		},
		hasData() {
			return this.data.length > 0;
		},
		pagination() {
			return this.warehouseOrders.data.pagination;
		},
		data() {
			try {
				return this.warehouseOrders.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		idsComputed() {
			const ids = [];
			Object.entries(this.ids).forEach(([key, value]) => {
				if (value) {
					ids.push(Number(key));
				}
			});
			return ids;
		},
	},
	watch: {
		selectAll: function watchAll(value) {
			if (value) {
				this.data.forEach((item) => {
					this.$set(this.ids, item.id, true);
				});
			} else if (!value) {
				this.$set(this, 'ids', {});
			}
		},
	},
	mounted() {
		this.warehouseId = this.$route.query.warehouseId;
		this.getData();
	},
	methods: {
		getDataFiltered(page) {
			this.pagination.current_page = page;
			this.getData(page);
		},
		getData(page = 1) {
			this.selectAll = false;
			this.ids = {};
			if (this.warehouseId) {
				this.warehouseOrders.getPendingOrders(this.warehouseId, page);
			}
		},
		exportOrders() {
			const trans = {
				title: this.translate('export_confirmation_title'),
				text: this.translate('export_confirmation_text', { qty: this.idsComputed.length }),
			};
			const options = {
				confirmButtonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
				config: {
					icon: 'info',
					showCancelButton: true,
					reverseButtons: true,
				},
			};
			this.alert.confirmation(trans.title, trans.text, options, false).then(() => {
				this.download.exportOrders({
					warehouse_id: this.warehouseId,
					orders: this.idsComputed,
				}).then(() => {
					this.getData();
					const { response } = this.download.data;
					const url = window.URL.createObjectURL(response.data);
					const link = document.createElement('a');
					link.href = url;
					const now = new Date();
					const filename = `warehouse_orders_${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;
					link.setAttribute('download', filename);
					this.$el.appendChild(link);
					link.click();
				});
			}).catch(() => {});
		},
	},
};
</script>
